export server_api = 'https://api.vixen.link/'
export server_host = 'https://up.vixen.link/'

export class APIError extends Error
  constructor: (request, ...args) ->
    super ...args
    @name = 'APIError'
    @request = request

  Object.defineProperties @prototype,
    status: configurable: true, get: -> @request.status
    statusText: configurable: true, get: -> @request.statusText

handle_request = (request, resolve, reject) ->
  request.onload = ->
    try
      body = JSON.parse(request.responseText)
    catch cause
      reject new APIError(request, 'Response invalid', { cause })
      return
    if body?.err? or not (request.status >= 200 and request.status < 300)
      reject new APIError(request, 'Response: ' + (body?.err or 'unknown'))
      return
    resolve body

  request.onerror = ->
    reject new APIError(request, 'Request failed')
  request.onabort = ->
    reject new APIError(request, 'Request aborted')
  request.ontimeout = ->
    reject new APIError(request, 'Request timed out')

  return

create_request = (method, endpoint) ->
  location = new URL(endpoint, server_api)
  (options) ->
    new Promise (resolve, reject) ->
      url = new URL(location)

      url_params = url.searchParams
      url_params.set(key, value) for key, value of options when key and key != 'auth'

      request = new XMLHttpRequest

      handle_request request, resolve, reject

      request.open method, url
      request.setRequestHeader 'Accept', 'application/json'
      request.setRequestHeader 'Authorization', options.auth if options.auth
      request.withCredentials = true
      request.send null

      setTimeout request.abort.bind(request), 5000

      return

export request_session_info = create_request 'GET', 'login'
export request_session_login = create_request 'POST', 'login'
export request_file_info = create_request 'GET', 'info'
export request_file_list = create_request 'GET', 'list'
export request_delete = create_request 'POST', 'delete'
export request_mirror = create_request 'POST', 'mirror'
export request_blacklist = create_request 'POST', 'blacklist'

export request_upload = (options, callback) ->
  new Promise (resolve, reject) ->
    options = Object.assign {}, options

    if typeof options.auth != 'string' or not options.auth
      throw new TypeError('Missing/invalid option "auth"')
    if typeof options.name != 'string' or not options.auth
      throw new TypeError('Missing/invalid option "name"')
    if typeof options.expires != 'number' and options.expires?
      throw new TypeError('Invalid option "expires"')
    if not options.body?
      throw new TypeError('Missing option "body"')

    request = new XMLHttpRequest

    handle_request request, resolve, reject

    if callback?
      request.upload.onprogress = (ev) ->
        callback ready: ev.lengthComputable, loaded: ev.loaded, total: ev.total
        return

    url = new URL(server_host)

    url_params = url.searchParams
    url_params.set 'name', options.name
    url_params.set 'expires', options.expires if options.expires? and options.expires > 0

    request.open 'POST', url
    request.setRequestHeader 'Accept', 'application/json'
    request.setRequestHeader 'Authorization', options.auth
    request.withCredentials = true
    request.send options.body

    return
