import 'bootstrap'
import { observe, computed } from 'patella'

import { $, h } from './dom.coffee'
import { request_session_info, request_session_login, request_file_list, request_upload } from './api.coffee'

storage_key = 'vixenlink-session'
storage =
  get: localStorage.getItem.bind localStorage
  set: localStorage.setItem.bind localStorage

$form = $ '#silly-form'
$file = $ '#silly-file'
$expires = $ '#silly-expires'
$submit = $ '#silly-submit'
$progress_container = $ '#silly-progress-container'
$progress = $ '#silly-progress'
$uploads = $ '#silly-uploads'
$history = $ '#silly-history'

model = observe
  locked: true
  progress: null

computed -> $submit.prop 'disabled', model.locked
computed ->
  if model.progress?
    $progress_container.removeClass 'd-none'
    $progress.width model.progress + '%'
    $progress.attr 'aria-valuenow', model.progress
  else
    $progress_container.addClass 'd-none'
    $progress.width '0'
    $progress.attr 'aria-valuenow', '0'

$file.val null
$expires.val '0'

$form.on "submit", (ev) ->
  ev.preventDefault()
  ev.stopImmediatePropagation()

  if not model.locked
    file = $file.prop('files').item(0)
    if file? then perform_upload file, parseInt($expires.val())

report = (error) ->
  queueMicrotask -> alert "Oops! #{error}"
  console.error error

login = ->
  auth = storage.get storage_key

  if auth
    session = await request_session_info { auth }
  else
    session = await request_session_login { invite: 'sillyupload' }

  storage.set storage_key, session.token

  return session.token

perform_upload = (file, expires) ->
  if model.locked then return else model.locked = true

  try
    auth = await login()

    file = await request_upload { auth, expires, body: file, name: file.name }, (status) ->
      if status.ready
        model.progress = String((status.loaded / status.total) * 100)
      else
        model.progress = null

    $link = h 'a', class: 'list-group-item list-group-item-action', href: file.href
    $link.text file.href

    $first = $uploads.children().first()
    $first.remove() if $first.hasClass 'disabled'

    $uploads.prepend $link

    return file

  catch cause
    report cause
    return null

  finally
    model.locked = false
    model.progress = null
    $file.val null

perform_refresh = () ->
  try
    auth = await login()
    list = await request_file_list { auth, limit: 500 }
  catch cause
    report cause
    return null

  $uploads.empty()
  $history.empty()

  $link_none = h 'a', class: 'list-group-item list-group-item-action disabled'
  $link_none.text 'None to show'

  $uploads.append $link_none.clone()

  if list.files.length > 0
    for file in list.files
      href = 'https://dl.vixen.link/' + file.id + '/' + encodeURIComponent(file.name)
      $link = h 'a', class: 'list-group-item list-group-item-action', href: href
      $link.text href
      $history.append $link
  else
    $history.append $link_none.clone()

  return list

main = ->
  await perform_refresh()
  model.locked = false

main().catch(report)
