import jquery from 'jquery'

children_collect = (values) ->
  nodes = []
  known = new Set

  process = (value) ->
    if not value?
      nodes.push document.createComment()
    else if typeof value == 'string' or typeof value == 'number'
      nodes.push document.createTextNode(value)
    else if typeof value == 'object' or typeof value == 'function'
      if not known.has value
        known.add value
        if value instanceof Node
          nodes.push value
        else if typeof value.length == 'number'
          process item for item in value
    return

  process values

  return nodes

children_set = (element, values) ->
  nodes_new = children_collect values
  nodes_cur = element.childNodes

  for [0...(nodes_cur.length - nodes_new.length)] by 1
    element.removeChild element.lastChild

  for node, i in nodes_new
    node_new = node
    node_cur = nodes_cur[i]
    if node_cur?
      element.replaceChild node_new, node_old if node_new != node_old
    else
      element.appendChild node_new

  return

create_element = (query, ...args) ->
  if $.isPlainObject args[0]
    props = args[0]
    children = args.slice(1)
  else
    props = null
    children = args

  parts = String(query).split /([#.])/

  $element = $ document.createElement(parts[0] or 'div')

  for i in [1...parts.length] by 2
    switch parts[i]
      when '#'
        $element.prop 'id', parts[i + 1]
      when '.'
        $element.addClass parts[i + 1]

  for key, value of props
    switch key
      when 'style'
        if $.isPlainObject value
          $element.css value
        else
          $element.prop 'style', value
      when 'class' or 'className'
        $element.addClass value
      when 'data' or 'dataset'
        $element.data value
      else
        $element.prop key, value

  children_set $element[0], children

  return $element

export $ = jquery
export h = create_element
